import React, { useEffect, useState } from "react";
import Tabs from "react-bootstrap/esm/Tabs";
import Tab from "react-bootstrap/Tabs";
import { splitOptions } from "../../Utils/Utils";
import { apiCallToken } from "../../Utils/Network";
import Loader from "../Common/loader";

function Result(props) {
  const [tabKey, setTabKey] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props) {
      setLoading(true);
      apiCallToken
        .get("student/tests/giventestdetail/" + props?.history?.location?.state)
        .then((res) => {
          setLoading(false);
          setData(res.data.data);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [props?.history?.location?.state]);

  useEffect(() => {
    return () => {
      if (props.history.action === "POP") {
        props.history.replace("/startTest");
      }
    };
  }, [props.history]);

  const handleSelect = (key) => {
    setTabKey(key);
  };

  const getMinutes = (millisecond) => {
    let mins = Math.round(formatNumberWithDecimal(millisecond) / 60000);

    return mins > 1 ? mins + " minutes" : mins + " minute";
  };

  function formatNumberWithDecimal(number) {
    const roundedNumber = Math.round(number * 100) / 100;

    const numberString = roundedNumber.toString();

    const [integerPart, decimalPart] = numberString.split(".");

    if (decimalPart && parseInt(decimalPart, 10) !== 0) {
      return numberString;
    }
    return integerPart;
  }

  const gettestDetails = (testId) => {
    return (
      <div className="qa-card">
        {!!data?.test_result_info &&
          data[testId]?.length !== 0 &&
          data[testId]?.map((item, idx) => {
            let user_ans = Array.isArray(item.user_ans)
              ? item.user_ans
              : [item.user_ans];
            var getSplitArray = [];
            getSplitArray = splitOptions(data[testId], item.options, idx);
            return (
              <div className="qa-card-ind">
                <div className="col-md-12" style={{ display: "flex" }}>
                  <div className="col-md-3">
                    <p>Question {idx + 1} </p>
                  </div>
                  <div className="col-md-4">
                    <p>Level : {item.level_id || 0}</p>
                  </div>
                  <div style={{ textAlign: "right" }} className="col-md-5">
                    {/* <p style={{ marginBottom: 5 }}>
                    Time Taken : {answerList[idx].time_taken || 0} seconds
                  </p>
                  {answerList[idx].student_previous_ans_time && (
                    <p style={{ margin: 0 }}>
                      Previous Time Taken :{" "}
                      {answerList[idx].student_previous_ans_time +
                        " seconds"}
                    </p>
                  )} */}
                  </div>
                </div>
                <hr />
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${item.question_desc}`,
                  }}
                ></p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 10,
                    padding: 20,
                  }}
                  className={`${item.ans_status === "WRONG"
                    ? "answer-is-wrong"
                    : item.ans_status === "CORRECT"
                      ? "answer-is-right"
                      : "not-answered"
                    }`}
                // className={
                //   user_ans[0] == "0" || user_ans.length === 0
                //     ? "not-answered"
                //     : ""
                // }
                >
                  <span>
                    {item.ans_status === "BLANK" && "Not Answered"}
                    {item.ans_status === "CORRECT" && "Correct Answer"}
                    {item.ans_status === "WRONG" && "Wrong Answer"}{" "}
                  </span>
                  <span>Time Taken: {getMinutes(item.time_taken)}</span>
                  {/* {answerList[idx].student_previous_ans_array?.length >
                  0 && (
                  <p style={{ margin: 0 }}>
                    Previous Answer:{" "}
                    {getSplitArray[0]
                      ?.filter((o) =>
                        answerList[
                          idx
                        ].student_previous_ans_array?.includes(
                          parseInt(o.option_mapping)
                        )
                      )
                      .map((o, i) => {
                        const parser = new DOMParser();
                        const value = parser.parseFromString(
                          o.options_val,
                          "text/html"
                        ).firstChild.textContent;
                        return (
                          <span
                            key={i}
                            style={{ margin: 0, textAlign: "right" }}
                          >
                            {i === 0 ? value : " , " + value}
                          </span>
                        );
                      })}
                  </p>
                )} */}
                </div>
                {/* <p
                  className={`${
                  correct == 1
                  ? "answer-is-right"
                  : correct == 0
                  ? "answer-is-wrong"
                  : "not-answered"
                  }`}
                  >
                  That's{" "}
                  {correct == 1
                  ? "Correct answer"
                  : correct == 0
                  ? "Wrong answer"
                  : "Not Answered"}{" "}
                  </p> */}
                <div className="checklist-answer-c">
                  {getSplitArray?.map((_item, _idx) => {
                    // const { wrong_array, correct_array } =
                    //   convertAnswer(item);
                    return (
                      <div
                        style={{
                          width: `${100 / getSplitArray.length}%`,
                        }}
                      >
                        {_item.map((newItem, newIdx) => {
                          let answer_type = "answer-not-attempted";
                          let option_id = Number(newItem.option_mapping);

                          if (newItem?.correct_option === "1") {
                            answer_type = "correct-answer";
                          }
                          if (
                            newItem?.correct_option === "0" &&
                            user_ans?.includes(newItem.option_mapping)
                          ) {
                            answer_type = "wrong-answer";
                          }
                          //   if (
                          //     correct_array !== null &&
                          //     correct_array.includes(option_id)
                          //   ) {
                          //     answer_type = "correct-answer";
                          //   } else if (
                          //     wrong_array !== null &&
                          //     wrong_array.includes(option_id)
                          //   ) {
                          //     answer_type = "wrong-answer";
                          //   }
                          // var myvalue = item.answer_ids.includes(
                          //   Number(newItem.option_mapping)
                          // );
                          var myvalue = 11;
                          return (
                            <div className="indi-options-c">
                              <div className="question-answer-ff">
                                <div className="check-ans-cf">
                                  {answer_type === "wrong-answer" &&
                                    myvalue &&
                                    "W"}
                                </div>
                                <span
                                  className={`${answer_type} alphabet-uppercase`}
                                  style={{
                                    textTransform: "uppercase",
                                    margin: "auto 0",
                                  }}
                                >
                                  {alphabet[newIdx]}
                                </span>
                                <div className="check-ans-cf">
                                  {answer_type == "correct-answer" &&
                                    myvalue &&
                                    "R"}
                                </div>
                              </div>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `${newItem.options_val}`,
                                }}
                              ></span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className="review-container" style={{ marginTop: "100px" }}>
      {loading && <Loader />}

      <Tabs activeKey={tabKey} onSelect={handleSelect}>
        <Tab eventKey={1} title="Summary" disabled={false}>
          {!!data?.test_result_info && (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: "45%",
                    padding: "24px",
                    border: "1px solid #dee2e6",
                    background: "rgb(246 246 246)",
                  }}
                >
                  <h4 style={{ textAlign: "center" }}>Verbal</h4>
                  <p>
                    Correct Answer :{" "}
                    {data?.test_result_info?.total_marks.verbal_marks}
                  </p>
                  <p>
                    Time taken:{" "}
                    {getMinutes(
                      data?.test_result_info?.total_time?.verbal_time
                    )}
                  </p>
                  <p>Marks : {data?.prediction_marks.verbal_marks}</p>
                </div>

                <div
                  style={{
                    width: "45%",
                    padding: "24px",
                    border: "1px solid #dee2e6",
                    background: "rgb(246 246 246)",
                  }}
                >
                  <h4 style={{ textAlign: "center" }}>Quant</h4>
                  <p>
                    Correct Answer :{" "}
                    {data?.test_result_info?.total_marks.quant_marks}
                  </p>
                  <p>
                    Time taken:{" "}
                    {getMinutes(data.test_result_info.total_time.quant_time)}
                  </p>
                  <p>Marks : {data?.prediction_marks.quant_marks}</p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "16px 0",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    padding: "24px",
                    border: "1px solid #dee2e6",
                    background: "rgb(246 246 246)",
                  }}
                >
                  <h4 style={{ textAlign: "center" }}>Total</h4>
                  <p>
                    Total marks :{" "}
                    {data?.test_result_info?.total_marks.total_marks}
                  </p>

                  <p>
                    Total time taken:{" "}
                    {getMinutes(data?.test_result_info?.total_time.total_time)}
                  </p>
                  <p>Total marks : {data?.prediction_marks.total_marks}</p>
                </div>
              </div>
            </>
          )}
        </Tab>
        <Tab eventKey={2} title="Essay" disabled={false}>
          {data?.awa_set && (
            <>
              <p>
                Q.1){" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: ` ${data?.awa_set[0]?.question_desc || ""}`,
                  }}
                />
              </p>

              <p>
                Answer :{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${data?.awa_set[0]?.user_ans[0] ?? ""}`,
                  }}
                />
              </p>
              {data?.test_result_info.awa_admin_status === '1' &&
                <div>
                  <p>Grade : <span>{data?.test_result_info.awa_admin_update.grade}</span></p>
                  <p>Sample : <span>{data?.test_result_info.awa_admin_update.sample}</span></p>
                  <p>Feedback : <span>{data?.test_result_info.awa_admin_update.feedback}</span></p>
                </div>
              }
            </>
          )}
        </Tab>
        <Tab eventKey={5} title="Verbal set -1" disabled={false}>
          {gettestDetails("verbal_set_one")}
        </Tab>
        <Tab eventKey={3} title="Quant set -1" disabled={false}>
          {gettestDetails("quant_set_one")}
        </Tab>
        <Tab eventKey={6} title="Verbal set -2" disabled={false}>
          {gettestDetails("verbal_set_two")}
        </Tab>
        <Tab eventKey={4} title="Quant set -2" disabled={false}>
          {gettestDetails("quant_set_two")}
        </Tab>
      </Tabs>
    </div>
  );
}

export default Result;
const alphabet = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
