import React, { useContext, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import HomePage from "../Pages/HomePage/HomePage";
// import NavigationBar from "../Components/NavigationBar/NavigationBar";
import NavigationBar from "../Components/navbar/navbar";
import test from "../Pages/test";
import Footer from "../Components/Footer/Footer";
import About from "../Pages/About/About";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "../Pages/TermsConditon/TermsCondition";
import Reference from "../Pages/Reference/Reference";
import Login from "../Pages/Login/Login";
import Price from "../Pages/Price/pricePage";
import Profile from "../Pages/Student/StudentProfile";
import GRE from "../Pages/Training/Gre";
import GMAT from "../Pages/Training/Gmat";
import IELTS from "../Pages/Training/Ielts";
import TOFEL from "../Pages/Training/Tofel";
import CONTACT from "../Pages/Login/Contact";
import ScholarshipLogin from "../Pages/Login/ScholarshipLogin";
import ScholarshipCreateAccount from "../Pages/Login/createScholarshipAccount";
import CreateAccount from "../Pages/Login/createAccount";
import Dashboard from "../Pages/Dashboard/Dashboard";
import StartTest from "../Pages/StartTest/StartTest";
import StartExam from "../Pages/StartExam/StartExam";
import Question from "../Pages/Questions/Question";
import Videos from "../Pages/Videos/Videos";
import { UserCredsContext } from "../ContextApis/UserCredsContext/UserCredsContext";
import Test from "../Pages/Test/Test";
import Testn from "../Pages/Testn/Testn";
import ReviewAnswer from "../Pages/ReviewAnswer/ReviewAnswer";
import Scholarship from "../Pages/Scholarship/Scholarship";
import TestResult from "../Pages/TestResult/TestResult";
import OtherComponents from "../Components/OtherComponents/OtherComponents";
import TestNew from "../Pages/TestNew/TestNew";
import Result from "../Pages/StartExam/Result";
export default function AppRouter() {
  return (
    <React.Fragment>
      <Router>
        <NavigationBar />
        <Switch>
          <Redirect from="/" to="/home" strict exact />
          <Route exact path="/home" component={HomePage} />
          {/* <Route exact path='/test' component={test} /> */}
          <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/termsCondition" component={TermsCondition} />
          <Route exact path="/about" component={About} />
          <Route exact path="/reference" component={Reference} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/price" component={Price} />
          <Route exact path="/gre" component={GRE} />
          <Route exact path="/gmat" component={GMAT} />
          <Route exact path="/ielts" component={IELTS} />
          <Route exact path="/tofel" component={TOFEL} />
          <Route exact path="/contact" component={CONTACT} />
          <Route exact path="/scholarshipLogin" component={ScholarshipLogin} />
          <Route
            exact
            path="/createScholarshipAccount"
            component={ScholarshipCreateAccount}
          />
          <Route exact path="/createAccount" component={CreateAccount} />
          <Route exact path="/other" component={OtherComponents} />
          <ProtectedRoutes exact path="/dashboard" component={Dashboard} />
          <ProtectedRoutes exact path="/profile" component={Profile} />
          <ProtectedRoutes exact path="/startTest" component={StartTest} />
          <ProtectedRoutes exact path="/result" component={Result} />
          <ProtectedRoutes exact path="/startExam" component={StartExam} />
          <ProtectedRoutes exact path="/video" component={Videos} />
          <ProtectedRoutes exact path="/question" component={Question} />
          <ProtectedRoutes exact path="/testresult" component={TestResult} />
          <ProtectedRoutes exact path="/scholarship" component={Scholarship} />
          <ProtectedRoutes exact path="/testn/:id/:countId" component={Test} />
          <ProtectedRoutes exact path="/test/:id/:countId" component={Testn} />
          <ProtectedRoutes exact path="/testnew/:id/:countId" component={TestNew} />
          <ProtectedRoutes
            exact
            path="/review/:p_id/:c_id"
            component={ReviewAnswer}
          />
        </Switch>
      </Router>
      <Footer />
    </React.Fragment>
  );
}

export const ProtectedRoutes = ({ component: CFomponent, ...rest }) => {
  const { _token } = useContext(UserCredsContext);

  return (
    <Fragment>
      <Route
        {...rest}
        render={(props) => {
          return _token ? <CFomponent {...props} /> : <Redirect to="/login" />;
        }}
      />
    </Fragment>
  );
};

// export const ProtectedRoutes = ({ component, ...rest }) => {
//     const { _token } = useContext(UserCredsContext);
//     return (
//         <React.Fragment>
//             <Route {...rest} render={(props) => {
//                 return _token ? <component {...props} /> : <Redirect to="/login" />
//             }} />
//         </React.Fragment>
//     )
// }
