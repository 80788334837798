import React, { useEffect, useState, PureComponent } from 'react';
import './Dashboard.css';
import {
   BarChart,
   Bar,
   Cell, LabelList, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import BackgroundAboutus from '../../Assets/About/BackgroundAboutus.png';
import AboutUsimages from '../../Assets/About/AboutUsimages.png'
import Faculty from '../../Assets/About/Faculty.png'
import team4 from '../../Assets/About/team-4.jpg'
import Background2aboutus from '../../Assets/About/Background2aboutus.png'
import Highlights from '../../Assets/About/Highlights.png';
import { Link } from 'react-router-dom';
import Chart from "react-google-charts";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
//import ReactSpeedometer from "react-d3-speedometer";
// DEVELOPMENT
import ReactSpeedometer from 'react-d3-speedometer/dist/index';
// PRODUCTION: switch to dist for checking production version
// import ReactSpeedometer from "../../dist/index"
// import SpeedoButton from './speedo-button';
// import MultiSpeedoMeters from './multi-speedometers';
// import AutoRefresh from './auto-refresh';
import { apiCallToken } from "../../Utils/Network.js";
import { dashboard_list } from "../../Utils/ApiUrls";
import { dashboard_listNew } from "../../Utils/ApiUrls";
import AOS from "aos";
import "aos/dist/aos.css";
function Dashboard() {
   useEffect(() => {
      AOS.init({
         offset: 200,
         duration: 600,
         easing: 'ease-in-sine',
         delay: 100,
      });
   }, []);
   useEffect(() => {
      getDashboardDataNew();
   }, []);
   const [total_verbal_question, set_total_verbal_question] = useState();
   const [total_verbal_user_question, set_total_verbal_user_question] = useState();
   const [total_verbal_practice, set_total_verbal_practice] = useState();
   const [total_verbal_user_practice, set_total_verbal_user_practice] = useState();
   const [total_quant_question, set_total_quant_question] = useState();
   const [total_quant_user_question, set_total_quant_user_question] = useState();
   const [total_quant_practice, set_total_quant_practice] = useState();
   const [total_quant_user_practice, set_total_quant_user_practice] = useState();
   const [total_number_of_question, set_total_number_of_question] = useState();
   const [user_total_number_of_question, set_user_total_number_of_question] = useState();
   const [user_total_correct_user_question, set_user_total_correct_user_question] = useState();
   const [total_verbal_correct_user_question, set_total_verbal_correct_user_question] = useState();
   const [total_quant_correct_user_question, set_total_quant_correct_user_question] = useState();

   const [verbal_bar_graph_data, set_verbal_bar_graph_data] = useState();
   const [quant_bar_graph_data, set_quant_bar_graph_data] = useState();

   const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
         xAxes: [{
            ticks: {
               minRotation: 0,
               maxRotation: 0
            }
         }]
      }
   };

   const data = [
      {
         name: '10 Min',
         Verbal: '10',
         Quantitative: '10',

      },
      {
         name: '20 Min',
         Verbal: '20',
         Quantitative: '74',

      },
      {
         name: '30 Min',
         Verbal: '30',
         Quantitative: '50',

      },
      {
         name: '40 Min',
         Verbal: '40',
         Quantitative: '20',

      },
      {
         name: '50 Min',
         Verbal: '50',
         Quantitative: '25',

      },
      {
         name: '60 Min',
         Verbal: '60',
         Quantitative: '30',

      },
      {
         name: '70 Min',
         Verbal: '70',
         Quantitative: '45',

      },
      {
         name: '80 Min',
         Verbal: '80',
         Quantitative: '20',

      },
      {
         name: '90 Min',
         Verbal: '90',
         Quantitative: '50',

      },
      {
         name: '100 Min',
         Verbal: '100',
         Quantitative: '80',

      },
   ];

   const data1 = [
      {
         name: 'SubTopic 1',
         Total_Questions: 50,
         Total_Questions_Attempted: 40,
         Correct_Answers: 10,
         amt: 2400,
      },
      {
         name: 'SubTopic 2',
         Total_Questions: 30,
         Total_Questions_Attempted: 15,
         Correct_Answers: 13,
         amt: 2210,
      },
      {
         name: 'SubTopic 3',
         Total_Questions: 20,
         Total_Questions_Attempted: 10,
         Correct_Answers: 8,
         amt: 2290,
      },
      {
         name: 'SubTopic 4',
         Total_Questions: 25,
         Total_Questions_Attempted: 20,
         Correct_Answers: 20,
         amt: 2000,
      },
      {
         name: 'SubTopic 5',
         Total_Questions: 20,
         Total_Questions_Attempted: 10,
         Correct_Answers: 4,
         amt: 2181,
      },
      {
         name: 'SubTopic 6',
         Total_Questions: 25,
         Total_Questions_Attempted: 20,
         Correct_Answers: 18,
         amt: 2500,
      },
      {
         name: 'SubTopic 7',
         Total_Questions: 30,
         Total_Questions_Attempted: 25,
         Correct_Answers: 20,
         amt: 2100,
      },
      {
         name: 'SubTopic 8',
         Total_Questions: 25,
         Total_Questions_Attempted: 25,
         Correct_Answers: 20,
         amt: 2000,
      },
      {
         name: 'SubTopic 9',
         Total_Questions: 20,
         Total_Questions_Attempted: 10,
         Correct_Answers: 4,
         amt: 2181,
      },
      {
         name: 'SubTopic 10',
         Total_Questions: 25,
         Total_Questions_Attempted: 20,
         Correct_Answers: 18,
         amt: 2500,
      },

   ];

   const renderCustomizedLabel = (props: any) => {
      const { x, y, width, value } = props;
      const radius = 10;

      return (
         <g>
            <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
            <text
               x={x + width / 2}
               y={y - radius}
               fill="#fff"
               textAnchor="middle"
               dominantBaseline="middle"
            >
               {value.split(" ")[1]}
            </text>
         </g>
      );
   };


   const getDashboardDataNew = () => {
      apiCallToken(`${dashboard_listNew}`)
         .then((response) => {
            console.log(response.data.total.Verbal, response.data.total.Quantitative, "response for dashboardNew");
            console.log("Total_Questions : ", response.data.total.total_question);
            var total_verbal_question = response.data.total.Verbal.question_count;
            set_total_verbal_question(total_verbal_question);
            var total_verbal_user_question = response.data.total.Verbal.user_question_count ? response.data.total.Verbal.user_question_count : 0;
            set_total_verbal_user_question(total_verbal_user_question);
            var total_verbal_correct_user_question = response.data.total.Verbal.user_correct_question_count ? response.data.total.Verbal.user_correct_question_count : 0;
            set_total_verbal_correct_user_question(total_verbal_correct_user_question);
            var total_verbal_practice = response.data.total.Verbal.practice_count;
            set_total_verbal_practice(total_verbal_practice);
            var total_verbal_user_practice = response.data.total.Verbal.user_practice_count ? response.data.total.Verbal.user_practice_count : 0;
            set_total_verbal_user_practice(total_verbal_user_practice);
            var total_quant_question = response.data.total.Quantitative.question_count;
            set_total_quant_question(total_quant_question);
            var total_quant_user_question = response.data.total.Quantitative.user_question_count ? response.data.total.Quantitative.user_question_count : 0;
            set_total_quant_user_question(total_quant_user_question);
            var total_quant_correct_user_question = response.data.total.Quantitative.user_correct_question_count ? response.data.total.Quantitative.user_correct_question_count : 0;
            set_total_quant_correct_user_question(total_quant_correct_user_question);
            var total_quant_practice = response.data.total.Quantitative.practice_count;
            set_total_quant_practice(total_quant_practice);
            var total_quant_user_practice = response.data.total.Quantitative.user_practice_count ? response.data.total.Quantitative.user_practice_count : 0;
            set_total_quant_user_practice(total_quant_user_practice);
            var total_number_of_question = response.data.total.total_question.total_question_count;
            set_total_number_of_question(total_number_of_question);
            var user_total_number_of_question = response.data.total.total_user_question.total_user_question_count;
            set_user_total_number_of_question(user_total_number_of_question);
            var user_total_correct_user_question = response.data.total.total_correct_user_question.total_correct_user_question;
            set_user_total_correct_user_question(user_total_correct_user_question);

            var v_graph_data = response.data.data.Verbal;
            set_verbal_bar_graph_data(v_graph_data);

            var q_graph_data = response.data.data.Quantitative;
            set_quant_bar_graph_data(q_graph_data);

         })
         .catch((error) => {
            console.log(error, "error fof video");
         });
   };
   return (
      <div>
         <section style={{ "marginTop": "8%" }}>
            <div className="container">
               <div className="row rowMargin" style={{ marginTop: '' }}>
                  <h2>Verbal</h2>
               </div>
               <div className="wrapper">
                  <div className="countbox">
                     <h6>Total Number of questions In Verbal </h6>
                     {"\n"}
                     <h3>{total_verbal_question}</h3>
                  </div>
                  <div className="countbox">
                     <h6>Total Number of questions You Attempted</h6>
                     {"\n"}
                     <h3>{total_verbal_user_question}</h3>
                  </div>
                  <div className="countbox">
                     <h6>Total Number of Practice Sets In Verbal </h6>
                     {"\n"}
                     <h3>{total_verbal_practice}</h3>
                  </div>
                  <div className="countbox">
                     <h6>Total Number of Practice Sets You Attempted </h6>
                     {"\n"}
                     <h3>{total_verbal_user_practice}</h3>
                  </div>
               </div>
            </div>
            <div className="container">
               <div className="row" style={{ marginTop: '24px' }}>
                  <h2>Quantitative</h2>
               </div>
               <div className="wrapper">
                  <div className="countbox">
                     <h6>Total Number of Questions In Quantitative </h6>
                     {"\n"}
                     <h3>{total_quant_question}</h3>
                  </div>
                  <div className="countbox">
                     <h6>Total Number of Questions You Attempted</h6>
                     {"\n"}
                     <h3>{total_quant_user_question}</h3>
                  </div>
                  <div className="countbox">
                     <h6>Total Number of Practice Sets In Quantitative </h6>
                     {"\n"}
                     <h3>{total_quant_practice}</h3>
                  </div>
                  <div className="countbox">
                     <h6>Total Number of Practice Sets You Attempted </h6>
                     {"\n"}
                     <h3>{total_quant_user_practice}</h3>
                  </div>
               </div>
            </div>
         </section>
         <section>
            <div className="container">
               <div className="row" style={{ marginTop: '8px', marginBottom:'12px' }}>
                  <h2>Overall Performance</h2>
               </div>
               <div className="col-md-12 overflow" style={{ display: "flex" }}>
                  <div className="col-md-4 graphbox">
                     <h6 style={{ textAlign: 'center' }}>Overall Score</h6>
                     <LineChart
                        width={365}
                        height={250}
                        data={data}
                        margin={{
                           top: 5,
                           right: 40,
                           left: 0,
                           bottom: 5
                        }}
                     >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                           type="monotone"
                           dataKey="Quantitative"
                           stroke="#8884d8"
                           activeDot={{ r: 8 }}
                        />
                        <Line type="monotone" dataKey="Verbal" stroke="#82ca9d" />
                     </LineChart>
                  </div>
                  <div className="col-md-4 graphbox">
                     <h6 style={{ textAlign: 'center' }}>Verbal</h6>
                     <LineChart
                        width={365}
                        height={250}
                        data={data}
                        margin={{
                           top: 5,
                           right: 40,
                           left: 0,
                           bottom: 5
                        }}
                     >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                           type="monotone"
                           dataKey="Verbal"
                           stroke="#8884d8"
                           activeDot={{ r: 8 }}
                        />
                        <Line type="monotone" dataKey="Verbal" stroke="#82ca9d" />
                     </LineChart>
                  </div>
                  <div className="col-md-4 graphbox">
                     <h6 style={{ textAlign: 'center' }}>Quantitative</h6>
                     <LineChart
                        width={365}
                        height={250}
                        data={data}
                        margin={{
                           top: 5,
                           right: 40,
                           left: 0,
                           bottom: 5
                        }}
                     >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                           type="monotone"
                           dataKey="Quantitative"
                           stroke="#8884d8"
                           activeDot={{ r: 8 }}
                        />
                        <Line type="monotone" dataKey="Quantitative" stroke="#82ca9d" />
                     </LineChart>
                  </div>
               </div>
            </div>
         </section>
         <section>
            <div className="container">
               <div className="row" style={{ marginTop: '24px' }}>
                  <h2>Questions Attempted</h2>
               </div>
               <div className="col-md-12 overflow" style={{ display: "flex" }}>
                  <div className="col-md-4 graphbox ">
                     <div className="speedometerBox"><div style={{ marginTop: '8px' }}><h5> Overall</h5>
                        <p>Total No. of Questions : {total_number_of_question}</p>
                        <p>Attempted Questions: {user_total_number_of_question}</p></div>
                        <div style={{ marginTop: '-10px', marginLeft: '30px' }}><ReactSpeedometer
                           width={160}
                           height={110}
                           needleHeightRatio={0.5}
                           forceRender={true}
                           maxSegmentLabels={1}
                           customSegmentStops={[]}
                           segmentColors={['#f34325']}
                           ringWidth={10}
                           needleColor={'black'}
                           minValue={0}
                           maxValue={total_number_of_question}
                           value={user_total_number_of_question}
                        />
                        </div></div>
                     <div style={{ textAlign: 'center', marginTop: '5px', color: 'red' }}><h5>Total Attempted Questions : {user_total_number_of_question}/{total_number_of_question}</h5></div>
                  </div>
                  <div className="col-md-4 graphbox ">
                     <div className="speedometerBox"><div style={{ marginTop: '8px' }}><h5> Verbal</h5>
                        <p>Total No. of Questions : {total_verbal_question}</p>
                        <p>Attempted Questions: {total_verbal_user_question}</p></div>
                        <div style={{ marginTop: '-10px', marginLeft: '30px' }}><ReactSpeedometer
                           width={160}
                           height={110}
                           needleHeightRatio={0.5}
                           forceRender={true}
                           maxSegmentLabels={1}
                           customSegmentStops={[]}
                           segmentColors={['#f34325']}
                           ringWidth={10}
                           needleColor={'black'}
                           minValue={0}
                           maxValue={total_verbal_question}
                           value={total_verbal_user_question}
                        />
                        </div></div>
                     <div style={{ textAlign: 'center', marginTop: '5px', color: 'red' }}><h5>Total Attempted Questions : {total_verbal_user_question}/{total_verbal_question}</h5></div>
                  </div>
                  <div className="col-md-4 graphbox ">
                     <div className="speedometerBox"><div style={{ marginTop: '8px' }}><h5> Quantitative</h5>
                        <p>Total No. of Questions : {total_quant_question}</p>
                        <p>Attempted Questions: {total_quant_user_question}</p></div>
                        <div style={{ marginTop: '-10px', marginLeft: '30px' }}><ReactSpeedometer
                           width={160}
                           height={110}
                           needleHeightRatio={0.5}
                           forceRender={true}
                           maxSegmentLabels={1}
                           customSegmentStops={[]}
                           segmentColors={['#f34325']}
                           ringWidth={10}
                           needleColor={'black'}
                           minValue={0}
                           maxValue={total_quant_question}
                           value={total_quant_user_question}
                        />
                        </div></div>
                     <div style={{ textAlign: 'center', marginTop: '5px', color: 'red' }}><h5>Total Attempted Questions : {total_quant_user_question}/{total_quant_question}</h5></div>
                  </div>
               </div>
            </div>
         </section>
         <section>
            <div className="container">
               <div className="row" style={{ marginTop: '24px' }}>
                  <h2>Accuracy Barometer</h2>
               </div>
               <div className="col-md-12 overflow" style={{ display: "flex" }}>
                  <div className="col-md-4 graphbox ">
                     <div className="speedometerBox"><div style={{ marginTop: '8px' }}><h5> Overall</h5>
                        <p>Total Questions Attempted : {user_total_number_of_question}</p>
                        <p>Correct Answers: {user_total_correct_user_question}</p></div>
                        <div style={{ marginTop: '-10px', marginLeft: '30px' }}><ReactSpeedometer
                           width={160}
                           height={110}
                           needleHeightRatio={0.5}
                           forceRender={true}
                           maxSegmentLabels={1}
                           customSegmentStops={[]}
                           segmentColors={['#f34325']}
                           ringWidth={10}
                           needleColor={'black'}
                           minValue={0}
                           maxValue={user_total_number_of_question}
                           value={user_total_correct_user_question}
                        />
                        </div></div>
                     <div style={{ textAlign: 'center', marginTop: '5px', color: 'red' }}><h5>Correct Answers : {user_total_correct_user_question}/{user_total_number_of_question}</h5></div>
                  </div>
                  <div className="col-md-4 graphbox ">
                     <div className="speedometerBox"><div style={{ marginTop: '8px' }}><h5> Verbal</h5>
                        <p>Questions Attempted: {total_verbal_user_question}</p>
                        <p>Correct Answers : {total_verbal_correct_user_question}</p></div>
                        <div style={{ marginTop: '-10px', marginLeft: '30px' }}><ReactSpeedometer
                           width={160}
                           height={110}
                           needleHeightRatio={0.5}
                           forceRender={true}
                           maxSegmentLabels={1}
                           customSegmentStops={[]}
                           segmentColors={['#f34325']}
                           ringWidth={10}
                           needleColor={'black'}
                           minValue={0}
                           maxValue={total_verbal_user_question}
                           value={total_verbal_correct_user_question}
                        />
                        </div></div>
                     <div style={{ textAlign: 'center', marginTop: '5px', color: 'red' }}><h5>Correct Answers: {total_verbal_correct_user_question}/{total_verbal_user_question}</h5></div>
                  </div>
                  <div className="col-md-4 graphbox ">
                     <div className="speedometerBox"><div style={{ marginTop: '8px' }}><h5> Quantitative</h5>
                        <p>Questions Attempted: {total_quant_user_question}</p>
                        <p>Correct Answers: {total_quant_correct_user_question}</p></div>
                        <div style={{ marginTop: '-10px', marginLeft: '30px' }}><ReactSpeedometer
                           width={160}
                           height={110}
                           needleHeightRatio={0.5}
                           forceRender={true}
                           maxSegmentLabels={1}
                           customSegmentStops={[]}
                           segmentColors={['#f34325']}
                           ringWidth={10}
                           needleColor={'black'}
                           minValue={0}
                           maxValue={total_quant_user_question}
                           value={total_quant_correct_user_question}
                        />
                        </div></div>
                     <div style={{ textAlign: 'center', marginTop: '5px', color: 'red' }}><h5>Correct Answers : {total_quant_correct_user_question}/{total_quant_user_question}</h5></div>
                  </div>
               </div>
            </div>
         </section>
         <section id="barGraph">
            <div className="container">
               <div className="row" style={{ marginTop: '24px' }}>
                  <h2>Topic Wise Accuracy - Verbal</h2>
               </div>

               <div className="col-md-12 overflow" style={{ display: "flex", marginTop: '20px' }}>
                  <div className="col-md-12 graphbox width">
                     <h6 style={{ textAlign: 'center' }}>Verbal</h6>
                     <BarChart
                        width={1200}
                        height={400}
                        data={verbal_bar_graph_data}
                        margin={{
                           top: 5,
                           right: 30,
                           left: 20,
                           bottom: 5
                        }}
                     >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="topic_name" fontSize="10px" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <options options="options" />
                        <Bar dataKey="question_count" fill="#004CB9" minPointSize={5}>
                        </Bar>

                        <Bar dataKey="user_question_count" fill="#7000B9" minPointSize={10} />
                        <Bar dataKey="user_correct_question_count" fill="#FF6400" minPointSize={10} />
                     </BarChart>
                  </div>
               </div>
            </div>
         </section>
         <section id="barGraph">
            <div className="container">
               <div className="row" style={{ marginTop: '24px' }}>
                  <h2>Topic Wise Accuracy - Quantitative</h2>
               </div>

               <div className="col-md-12 overflow" style={{ display: "flex", marginTop: '20px' }}>
                  <div className="col-md-12 graphbox width">
                     <h6 style={{ textAlign: 'center' }}>Quantitative</h6>
                     <BarChart
                        width={1200}
                        height={400}
                        data={quant_bar_graph_data}
                        margin={{
                           top: 5,
                           right: 30,
                           left: 20,
                           bottom: 5
                        }}
                     >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="topic_name" fontSize="10px" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="question_count" fill="#004CB9" minPointSize={5}>
                        </Bar>Total_Questions_Attempted

                        <Bar dataKey="user_question_count" fill="#7000B9" minPointSize={10} />
                        <Bar dataKey="user_correct_question_count" fill="#FF6400" minPointSize={10} />
                     </BarChart>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}
export default Dashboard;
