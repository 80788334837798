import React from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';

function test() {
    return (
        <div>
            hello
            <Container>
                <Row xs={4} md={4} lg={4}>
                    <Col>1 of 2</Col>
                    <Col>2 of 2</Col>
                    <Col>2 of 2</Col>

                </Row>
                <Row xs={1} md={2}>
                    <Col>1 of 3</Col>
                    <Col>2 of 3</Col>
                    <Col>3 of 3</Col>
                </Row>
            </Container>
        </div>
    )
}

export default test;